exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-apps-my-sky-index-js": () => import("./../../../src/pages/apps/my-sky/index.js" /* webpackChunkName: "component---src-pages-apps-my-sky-index-js" */),
  "component---src-pages-apps-sky-go-index-js": () => import("./../../../src/pages/apps/sky-go/index.js" /* webpackChunkName: "component---src-pages-apps-sky-go-index-js" */),
  "component---src-pages-apps-sky-kids-index-js": () => import("./../../../src/pages/apps/sky-kids/index.js" /* webpackChunkName: "component---src-pages-apps-sky-kids-index-js" */),
  "component---src-pages-apps-sky-news-index-js": () => import("./../../../src/pages/apps/sky-news/index.js" /* webpackChunkName: "component---src-pages-apps-sky-news-index-js" */),
  "component---src-pages-apps-sky-sports-index-js": () => import("./../../../src/pages/apps/sky-sports/index.js" /* webpackChunkName: "component---src-pages-apps-sky-sports-index-js" */),
  "component---src-pages-broadband-check-availability-js": () => import("./../../../src/pages/broadband/check-availability.js" /* webpackChunkName: "component---src-pages-broadband-check-availability-js" */),
  "component---src-pages-broadband-index-js": () => import("./../../../src/pages/broadband/index.js" /* webpackChunkName: "component---src-pages-broadband-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-index-js": () => import("./../../../src/pages/mobile/index.js" /* webpackChunkName: "component---src-pages-mobile-index-js" */),
  "component---src-pages-mobile-mix-js": () => import("./../../../src/pages/mobile/mix.js" /* webpackChunkName: "component---src-pages-mobile-mix-js" */),
  "component---src-pages-mobile-roll-js": () => import("./../../../src/pages/mobile/roll.js" /* webpackChunkName: "component---src-pages-mobile-roll-js" */),
  "component---src-pages-mobile-swap-js": () => import("./../../../src/pages/mobile/swap.js" /* webpackChunkName: "component---src-pages-mobile-swap-js" */),
  "component---src-pages-mobile-text-to-switch-js": () => import("./../../../src/pages/mobile/text-to-switch.js" /* webpackChunkName: "component---src-pages-mobile-text-to-switch-js" */),
  "component---src-pages-mobile-watch-js": () => import("./../../../src/pages/mobile/watch.js" /* webpackChunkName: "component---src-pages-mobile-watch-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-tv-glass-configurator-js": () => import("./../../../src/pages/tv/glass/configurator.js" /* webpackChunkName: "component---src-pages-tv-glass-configurator-js" */),
  "component---src-pages-tv-glass-index-js": () => import("./../../../src/pages/tv/glass/index.js" /* webpackChunkName: "component---src-pages-tv-glass-index-js" */),
  "component---src-pages-tv-glass-tech-specs-js": () => import("./../../../src/pages/tv/glass/tech-specs.js" /* webpackChunkName: "component---src-pages-tv-glass-tech-specs-js" */),
  "component---src-pages-tv-index-js": () => import("./../../../src/pages/tv/index.js" /* webpackChunkName: "component---src-pages-tv-index-js" */),
  "component---src-pages-tv-packages-index-js": () => import("./../../../src/pages/tv/packages/index.js" /* webpackChunkName: "component---src-pages-tv-packages-index-js" */),
  "component---src-pages-tv-q-index-js": () => import("./../../../src/pages/tv/q/index.js" /* webpackChunkName: "component---src-pages-tv-q-index-js" */),
  "component---src-pages-tv-reviews-js": () => import("./../../../src/pages/tv/reviews.js" /* webpackChunkName: "component---src-pages-tv-reviews-js" */),
  "component---src-pages-tv-stream-index-js": () => import("./../../../src/pages/tv/stream/index.js" /* webpackChunkName: "component---src-pages-tv-stream-index-js" */),
  "component---src-pages-vip-index-js": () => import("./../../../src/pages/vip/index.js" /* webpackChunkName: "component---src-pages-vip-index-js" */),
  "component---src-templates-android-template-js": () => import("./../../../src/templates/android-template.js" /* webpackChunkName: "component---src-templates-android-template-js" */),
  "component---src-templates-ios-template-js": () => import("./../../../src/templates/ios-template.js" /* webpackChunkName: "component---src-templates-ios-template-js" */)
}

